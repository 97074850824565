import { Controller } from "@hotwired/stimulus"
import videojs from "video.js"

export default class extends Controller {
  static targets = ["player"]

  connect() {
    if (!this.hasPlayerTarget) return

    this.player = videojs(this.playerTarget, {
      controls: true,
      preload: "auto",
      responsive: true,
      fluid: true,
      playbackRates: [0.5, 1, 1.5, 2]
    })
  }

  disconnect() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
