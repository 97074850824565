import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

window.tippy = tippy;
export default function() {
  tippy('[data-tooltip]', {
    content: (reference) => reference.getAttribute('data-tooltip'),
    theme: 'light'
  });
}
