import SignaturePad from 'signature_pad/dist/signature_pad.js'
window.SignaturePad = SignaturePad;

export default function() {

  $('[data-signature-modal-toggle]').click(function(e){
    var target = $(e.target);
    $(target.attr('data-signature-modal-toggle')).modal('show');
    e.preventDefault();
  });

  // Drawing signature
  var enabled = false;

  var enable = function(canvas){
    if(!enabled) {
      enabled = true;
      $(canvas).closest('form')
        .find('[data-signature="button"]')
        .attr('disabled', null);
    }
  }
  var disable = function(canvas){
    if(enabled) {
      enabled = false;
      $(canvas).closest('form').find('[data-signature="button"]').attr('disabled', 'disabled');
    }
  }

  var create = function(canvas) {
    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    var sp = new SignaturePad(canvas, {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: 'rgb(0, 0, 0)',
      onEnd: function() {
        if(!sp.isEmpty()) {
          $(canvas).closest('[data-signature="root"]').find('[data-signature="field"]').val(sp.toDataURL('image/png'));
        }
      }
    });

    canvas.addEventListener('mousedown', function(){
      enable(canvas);
    });

    canvas.addEventListener('mouseleave', function(){
      if (!sp.isEmpty()) {
        $(canvas).closest('[data-signature="root"]').find('[data-signature="field"]').val(sp.toDataURL('image/png'));
      }
    });
    canvas.addEventListener('touchstart', function(){
      enable(canvas);
    });

    canvas.addEventListener('mouseup', function(){
      $(canvas).closest('[data-signature="root"]').find('[data-signature="field"]').val(sp.toDataURL('image/png'));
    });

    canvas.addEventListener('touchend', function(){
      $(canvas).closest('[data-signature="root"]').find('[data-signature="field"]').val(sp.toDataURL('image/png'));
    });

    $(canvas).closest('[data-signature="root"]').find('[data-signature="clear"]').click(function(e) {
      sp.clear();
      $(canvas).closest('[data-signature="root"]').find('[data-signature="field"]').val('');
      disable(canvas);
    });
  }

  var canvas = $('[data-signature="pad"]');
  var modal = canvas.closest('.js-signature-modal');

  if(canvas.length > 0) {
    if(modal.length > 0) {
      modal.on('shown.bs.modal', function(){
        console.log('Enabling signature pad via shown.bs.modal');
        create(canvas[0]);
      })
    } else {
      console.log('Enabling signature pad');
      create(canvas[0]);
    }
  }
}
